import React, { createContext, useReducer } from "react";

export const LanguageContext = createContext();

const initialState = {
    language: 'fr',
};

const themeReducer = (state, action) => {
    switch (action.type) {
        case "FRENCH":
            return { language: 'fr' };
        case "ENGLISH":
            return { language: 'en' };
        default:
            return state;
    }
};

export function LanguageProvider(props) {
    const [state, dispatch] = useReducer(themeReducer, initialState);

    return <LanguageContext.Provider value={{ state, dispatch }}>{props.children}</LanguageContext.Provider>;
}
