import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./Footer.module.scss";
import mpesa_logo from "../../assets/photos/Mpesa-Logo-bw.png";
import orange_logo from "../../assets/photos/Orange-Money-logo-bw.png";
import airtel_logo from "../../assets/photos/Airtel-Money-bw.png";
import olymique_logo from "../../assets/photos/OIP1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
export default function Footer() {
  const { t } = useTranslation("translations");

  return (
    <div className={classes.footer_container}>
      <div className={classes.logos_container}>
        <img className={classes.logo_image} alt="logo mpesa" src={mpesa_logo} />
        <img
          className={classes.logo_image}
          alt="logo orange"
          src={orange_logo}
        />
        <img
          className={classes.logo_image}
          alt="logo airtel"
          src={airtel_logo}
        />
        <img
          className={classes.logo_image}
          alt="logo olympique"
          src={olymique_logo}
        />
      </div>
      <div className={classes.socialMedia_container}>
        <a className={classes.icon} href="https://www.linkedin.com/company/moja-group-inc/" target="_blank">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a className={classes.icon} href="https://www.instagram.com/mojagroupinc/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a className={classes.icon} href="https://www.facebook.com/mojagroupinc/" target="_blank">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a className={classes.icon} href="https://twitter.com/IncMoja" target="_blank">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      <span className={classes.copyright}>
        {t("copyright © 2023 mojabet. tous droits réservés.")}
      </span>
    </div>
  );
}
