import React, { useState,useEffect } from "react";
import axios from "axios";
import classes from "./Contactus.module.scss";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import Returnmessage from "../Returnmessage/Returnmessage";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";

export default function Contactus() {
  const { t } = useTranslation("translations");
  const recaptchaRef = React.createRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    setStatus(null);
    setLoading(<Loader />);
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_ENDPOINT,
      data: {
        messageHtml: `<div> <p>Name: ${name} </p><p>Email: ${email} </p><p>Message: ${message}</p></div>`,
      },
      // data: {
      //   messageHtml: `<div> <p>phoneNumber: ${phoneNumber} </p></div>`,
      // },
    })
      .then((response) => {
        if (response.data.msg === "success") {
          setLoading(null);
          setName("");
          setEmail("");
          setMessage("");
          setStatus(1);
          setResponseMessage("Email sent !");
        } else {
          setLoading(null);
          setResponseMessage("Oops, something went wrong. Try again");
          setStatus(0);
        }
        setLoading(null);
      })
      .catch((err) => {
        setLoading(false);
        setResponseMessage("Oops, something went wrong. Try again");
        setStatus(0);
      });
  };
  useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/contactus" });
	}, [])

  return (
    <div className={classes.contactus_page}>
      <div className="container">
        <span className={classes.pageTitle}>{t("contactez-nous")}</span>
        <span className={classes.pageSubtitle}>
          {t(
            "Laissez-nous vos coordonnées - nous vous répondrons le jour même !"
          )}
        </span>
        <form onSubmit={submitForm}>
          <div className={classes.inputGroupe}>
            <span className={classes.inputLabel}>
              {t("Votre nom complet")}*
            </span>
            <input
              type="text"
              className={classes.inputField}
              required
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={classes.inputGroupe}>
            <span className={classes.inputLabel}>
              {t("Votre Adresse électronique")}*
            </span>
            <input
              type="text"
              className={classes.inputField}
              required
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={classes.inputGroupe}>
            <span className={classes.inputLabel}>{t("Votre message")}*</span>
            <input
              type="text"
              className={classes.inputField}
              required
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className={classes.buttonContainer}>
            <button type="submit" className={classes.sendButton}>
              {t("envoyer")}
            </button>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LdzO5cmAAAAANf9vp8lhMDxzh4_GoprOYnw3DEy"
          />
          </div>
        </form>
        {loading}
        {status === null ? (
          ""
        ) : status === 1 ? (
          <Returnmessage message={responseMessage} type="success" />
        ) : (
          <Returnmessage message={responseMessage} type="error" />
        )}
      </div>
    </div>
  );
}
