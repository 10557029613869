import "./App.scss";
import React from "react";
import { Route, Routes} from "react-router-dom";
import Homepage from "./containers/Homepage";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize([
    {
      trackingId: "G-B6553JRP66",
    },
  ]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage/>}/>
      </Routes>
    </div>
  );
}

export default App;
