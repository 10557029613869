import React, { useEffect, useState } from "react";
import classes from "./LandingPage.module.scss";
import congophoto from "../../assets/photos/congo-photo.png";
import senegalphoto from "../../assets/photos/senegal-photo.png";
import tanzaniaphoto from "../../assets/photos/tanzania-photo.png";
import livephoto from "../../assets/photos/live-photo.png";
import teamphoto from "../../assets/photos/footballBg.png";
import { useTranslation } from "react-i18next";
import Contactus from "../Contactus/Contactus";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import axios from "axios";
import Loader from "../Loader/Loader";
import Returnmessage from "../Returnmessage/Returnmessage";

export default function LandingFirst(props) {
  const { t } = useTranslation("translations");
  const [show, setShow] = useState(false);
  const [phoneNumber,setPhoneNumber] = useState('');
  const [responseMessage,setResponseMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(null);

  const submitForm = (e) => {
    e.preventDefault();
    setStatus(null);
    setLoading(<Loader />);
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_ENDPOINT,
      data: {
        messageHtml: `<div> <p>phoneNumber: ${phoneNumber} </p></div>`,
      },
    })
      .then((response) => {
        if (response.data.msg === "success") {
          setLoading(null);
          setStatus(1);
          setResponseMessage("Subscribed");
        } else {
          setLoading(null);
          setResponseMessage("Oops, something went wrong. Try again");
          setStatus(0);
        }
        setLoading(null);
      })
      .catch((err) => {
        setLoading(false);
        setResponseMessage("Oops, something went wrong. Try again");
        setStatus(0);
      });
  };
  return (
    <>
      <div className={classes.landingpage}>
        <Header />
        <section
          className={classes.page1 + " " + [show ? classes.hidepage1 : ""]}
        >
          <div className="container">
            <div className={classes.content}>
              <div className={classes.dataBlock}>
                <span className={classes.pageTitle}>
                  {t("bienvenue")} <br /> {t("à mojabet")}
                </span>
                <div className={classes.countriesContainer}>
                  <div className={classes.title_wrapper}>
                    <span className={classes.countriesTitle}>
                      {t("les pays")}
                    </span>
                  </div>
                  <div className={classes.countryWrapper}>
                    <img
                      className={classes.countryImage}
                      src={congophoto}
                      alt="countryphoto"
                    />
                    <span className={classes.countryName}>dr congo</span>
                    <a href="https://www.mojabet.cd/" target="_blank" rel="noreferrer">
                      <img
                        src={livephoto}
                        className={classes.livePhoto}
                        alt="livephoto"
                      />
                    </a>
                  </div>
                  <div className={classes.countryWrapper} >
                    <img
                      className={classes.countryImage}
                      src={senegalphoto}
                      alt="countryphoto"
                    />
                    <span className={classes.countryName}>senegal</span>
                    <a href="https://www.acajou.sn/" target="_blank" rel="noreferrer">
                      <img
                        src={livephoto}
                        className={classes.livePhoto}
                        alt="livephoto"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className={classes.dataBlock}>
                <div className={classes.imageBlock}>
                  <img
                    src={teamphoto}
                    alt="landing-photos"
                    className={classes.team_image}
                  />
                </div>
                <div className={classes.subscriptionBlock}>
                  <h3>{t("Découvrez MOJABET : Où l'Excitation Rencontre l'Opportunité")}</h3>
                  <p>{t("Bienvenue sur MOJABET, là où l'excitation rencontre l'opportunité. Plus qu'une simple marque de paris sportifs et de casino en ligne, nous offrons le meilleur service, les meilleures cotes et les derniers jeux de casino. Chez MOJABET, nous avons conçu une plateforme pour offrir une expérience de jeu de classe mondiale, adaptée spécialement pour vous.")}</p>
                  <form onSubmit={submitForm}>
                      <span className={classes.inputLabel}>
                        {t("Votre numéro de téléphone")}*
                      </span>
                    <div className={classes.inputGroup}>
                      <input
                        type="number"
                        className={classes.inputField}
                        required
                        name="phoneNumber"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <button className={classes.subscribe_button}>{t("s'inscrire")}</button>
                    </div>
                  </form>
                  {loading}
        {status === null ? (
          ""
        ) : status === 1 ? (
          <Returnmessage message={responseMessage} type="success" />
        ) : (
          <Returnmessage message={responseMessage} type="error" />
        )}
                </div>
              </div>
            </div>
            <div
              className={
                classes.screen_fadeup +
                " " +
                [show ? classes.screen_fadeup_hide : ""]
              }
              onClick={() => setShow(true)}
            >
              <span>{t("contactez-nous")}</span>
              <i className={classes.arrow_down}></i>
            </div>
          </div>
        </section>
        <section
          className={
            classes.page2 + " " + [show ? classes.showPage2 : classes.hidePage2]
          }
        >
          <Contactus />
          <div className="container">
            <div className={classes.backcontainer} onClick={() => setShow(false)}>
              <i className={classes.arrow_up} />
            </div>

          </div>
          <Footer />
        </section>
      </div>
    </>
  );
}
