import React, { useContext } from "react";
import { LanguageContext } from "../../LanguageContext";

export const ThemeSwitch = ({ isOn, handleToggle }) => {
	const theme = useContext(LanguageContext);
	const language = theme.state.language;

	// console.log(language);
	const changeTheme = () => {
		console.log(language);
		if (language==='fr') {
			theme.dispatch({ type: "ENGLISH" });
		} else {
			theme.dispatch({ type: "FRENCH" });
		}
	};

	return (
		<>
			<input
				onChange={changeTheme}
				className="react-switch-checkbox"
				id={`react-switch-new`}
				type="checkbox"
			/>
			<label
				className="react-switch-label"
				htmlFor={`react-switch-new`}
			>
				<span className={`react-switch-button`} />
			</label>
		</>
	);
}
