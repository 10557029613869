import React,{useContext, useEffect} from 'react'
import classes from './header.module.scss';
import logo from '../../assets/photos/logomojabet.png';
import { ThemeSwitch } from '../ThemeSwitch/ThemeSwitch';
import { LanguageContext } from '../../LanguageContext';
import i18n from "../../i18n";

function Header() {
	const theme = useContext(LanguageContext);
	const language = theme.state.language;

  console.log('langDetector',i18n.language)
  useEffect(() => {
    if(language) {
      localStorage.setItem("lng",language);
      i18n.changeLanguage(language);
    }
  },[language])
  return (
    <div className={classes.header_container}>
      <div className='container'>
        <div className={classes.wrapper}>
            <img className={classes.logo} src={logo} alt='logomojabet'/>
        <div className={"theme-switch"}>
            <label>FR</label>
            <ThemeSwitch/>
            <label>EN</label>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Header;